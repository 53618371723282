import React, { useState, useEffect } from "react";
import firebase from "../../FirebaseConfig";
import ExpenseCard from "./ExpenseCard";
//import Login from "./auth/Login";
//import SignUp from "./auth/SignUp";

const VehicleExpenses = () => {
  const [expenses, setExpenses] = useState([]);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [filterType, setFilterType] = useState("insurance");

  useEffect(() => {
    const expensesRef = firebase.firestore().collection("vehicle-expenses");

    // Modify the query to order by "Mileage" in ascending order
    expensesRef
      .orderBy("Mileage")
      .get()
      .then((querySnapshot) => {
        const expenseData = [];
        querySnapshot.forEach((doc) => {
          const expense = {
            id: doc.id,
            ...doc.data(),
          };
          expenseData.push(expense);
        });

        // Set the data in the state
        setExpenses(expenseData);
      });

    // Since you want the data to persist until the page is refreshed,
    // you don't need to specify any dependencies for this effect.
    // It will only run once when the component mounts.
  }, []);

  // Add each of the expenses to the filteredExpenses array
  useEffect(() => {
    const filteredExpenses = [];
    expenses.forEach((expense) => {
      if (filterType === "none") {
        filteredExpenses.push(expense);
      } else if (filterType === "MOT") {
        if (expense["MOT?"] === "Yes") {
          filteredExpenses.push(expense);
        }
      } else if (filterType === "insurance") {
        if (expense["Insurer"]) {
          filteredExpenses.push(expense);
        }
      }
    });

    // Set the data in the state
    setFilteredExpenses(filteredExpenses);
  }, [expenses, filterType]);

  //create a function to setfilter type

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };

  return (
    <div>
      <h1>Vehicle Expenses</h1>
      <button onClick={handleFilterTypeChange} value="none">
        Show all
      </button>
      <button onClick={handleFilterTypeChange} value="MOT">
        Show MOT
      </button>
      <button onClick={handleFilterTypeChange} value="insurance">
        Show insurance
      </button>

      {filteredExpenses.map((expense) => (
        <div key={expense.id}>
          <ExpenseCard
            mileage={expense.Mileage}
            litres={expense["Total (Litres)"]}
            discs={expense["Brake discs?"]}
            pads={expense["Brake pads?"]}
            details={expense["Details of other work carried out"]}
            type={expense["Expense type"]}
            deets={expense["Give details of other expenses"]}
            insurer={expense["Insurer"]}
            mot={expense["MOT?"]}
            notes={expense["Notes"]}
            date={expense["Timestamp"]}
            spend={expense["Total spend (£)"]}
            tyres={expense["Tyres replaced?"]}
          />
        </div>
      ))}
    </div>
  );
};

export default VehicleExpenses;
