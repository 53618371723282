import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RandomQuote from "./components/quotes/RandomQuote";
import Home from "./Home";
import Quilt from "./components/quilt/Quilt";
import Experiments from "./components/probability/Experiment";
import Tracker from "./components/vehicle-tracker/Tracker";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/quotes" element={<RandomQuote />} />
          <Route path="/" element={<Home />} />
          <Route path="/quilt" element={<Quilt />} />
          <Route path="/probability-experiments" element={<Experiments />} />
          <Route path="/vehicle-tracker" element={<Tracker />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
