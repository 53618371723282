const Home = () => {
  return (
    <div className="grid h-screen place-items-center bg-slate-100">
      <h1 className="md:text-8xl text-4xl text-slate-300 align-middle">
        REVELEIGH.COM
      </h1>
    </div>
  );
};

export default Home;
