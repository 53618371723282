class Polygon {
  constructor(vertices, cutting_order) {
    this.vertices = vertices;
    this.cutting_order = cutting_order;
    this.centrePoint = this.calculateCenter();
    this.color = [255, 255, 255];
  }

  calculateCenter() {
    const vertexCount = this.vertices.length;
    let sumX = 0;
    let sumY = 0;

    for (let i = 0; i < vertexCount; i++) {
      sumX += this.vertices[i].x;
      sumY += this.vertices[i].y;
    }

    return {
      x: sumX / vertexCount,
      y: sumY / vertexCount,
    };
  }

  setColor(color) {
    this.color = color;
  }
}
const getRandomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

// Perform point and polygon generation logic and return an array of polygons
// BlockSize is passed in from the Points component and set in the Quilt component
const generatePolygons = (blockSize) => {
  const points = []; // Generated points
  const polygons = []; // Generated polygons

  // First point in the lower half on the left edge
  let left1 = getRandomNumber(
    Math.round(blockSize / 2),
    Math.round(blockSize * 0.94)
  );
  points.push({ x: 0, y: left1 });

  // Second point between the bottom and first point on the left edge
  let left2 = getRandomNumber(left1, Math.round(blockSize * 0.98));
  points.push({ x: 0, y: left2 });

  // Space above first point on left edge split into thirds and margin added
  // so points are not too close to each other or edge of page
  let third = Math.trunc(left1 / 3);
  let margin = Math.round(left1 * 0.1);

  // Third point placed in lower third of upper left edge
  let left3 = getRandomNumber(2 * third, left1 - margin);
  points.push({ x: 0, y: left3 });

  // Fourth point placed in middle third of upper left edge
  let left4 = getRandomNumber(third, 2 * third - margin);
  points.push({ x: 0, y: left4 });

  // Fifth point added to upper third of upper left edge
  let left5 = getRandomNumber(0, third - margin);
  points.push({ x: 0, y: left5 });

  // Sixth point placed along the bottom edge but not too close to corners
  let bottom6 = getRandomNumber(
    Math.round(blockSize * 0.2),
    Math.round(blockSize * 0.8)
  );
  points.push({ x: bottom6, y: blockSize });

  // Seventh point placed in upper half of right edge
  let right7 = getRandomNumber(
    Math.round(blockSize * 0.06),
    Math.round(blockSize / 2)
  );
  points.push({ x: blockSize, y: right7 });

  // Eight point placed below seventh point but not too close to the bottom
  margin = Math.round((blockSize - right7) * 0.1);
  let right8 = getRandomNumber(right7 + margin, Math.round(blockSize * 0.94));
  points.push({ x: blockSize, y: right8 });

  // Nineth point placed between eights and the bottom
  margin = Math.round((blockSize - right8) * 0.1);
  let right9 = getRandomNumber(right8 + margin, Math.round(blockSize * 0.98));
  points.push({ x: blockSize, y: right9 });

  // Vertices of the mid point line
  let startX = 0;
  let endX = blockSize;
  let startY = left1;
  let endY = right7;

  // Function to return the y coordinate of a point along the mid point line
  // where the x point is known
  const receiveY = (xInput) => {
    return startY + ((endY - startY) / (endX - startX)) * (xInput - startX);
  };

  // Place three points along the mid point line roughly in their own third
  for (let i = 0; i < 3; i++) {
    let xMin = startX + (endX - startX) * (i / 3);
    let xMax = startX + (endX - startX) * ((i + 1) / 3);
    let point = getRandomNumber(
      Math.max(xMin + 10, startX + 10),
      Math.min(xMax - 10, endX - 10)
    );
    points.push({ x: point, y: receiveY(point) });
  }

  polygons.push(
    new Polygon([points[8], { x: blockSize, y: blockSize }, points[5]], 7)
  );
  polygons.push(
    new Polygon(
      [points[7], points[8], points[5], { x: 0, y: blockSize }, points[1]],
      6
    )
  );
  polygons.push(new Polygon([points[6], points[7], points[1], points[0]], 5));
  polygons.push(new Polygon([points[2], points[9], points[0]], 1));
  polygons.push(new Polygon([points[3], points[10], points[9], points[2]], 2));
  polygons.push(new Polygon([points[4], points[11], points[10], points[3]], 3));
  polygons.push(
    new Polygon(
      [
        { x: 0, y: 0 },
        { x: blockSize, y: 0 },
        points[6],
        points[11],
        points[4],
      ],
      4
    )
  );

  return polygons;
};

export default generatePolygons;
