import React, { useState, useEffect } from "react";
import firebase from "../../FirebaseConfig";
import { Helmet } from "react-helmet";

const db = firebase.firestore();

const RandomQuote = () => {
  const [randomTitle, setRandomTitle] = useState("");
  const [randomAuthor, setRandomAuthor] = useState("");
  const [randomQuote, setRandomQuote] = useState("");
  const [randomImage, setRandomImage] = useState("");

  useEffect(() => {
    const getRandomName = async () => {
      const randomValue = Math.random();
      const snapshot = await db
        .collection("quotes")
        .orderBy("randomValue")
        .where("randomValue", ">=", randomValue)
        .limit(1)
        .get();
      setRandomTitle(snapshot.docs[0].data().title);
      setRandomAuthor(snapshot.docs[0].data().author);
      setRandomQuote(snapshot.docs[0].data().annotation);
      setRandomImage("media/quotes/" + snapshot.docs[0].data().image);
    };
    getRandomName();
  }, []);

  return (
    <div className="bg-indigo-500 h-screen">
      <Helmet>
        <title>Quotes</title>
      </Helmet>
      <h1>Title: {randomTitle}</h1>
      <h2>Author: {randomAuthor}</h2>
      <p>Quote: {randomQuote}</p>
      <img
        src={randomImage}
        width="200px"
        alt={randomImage}
        classname="absolute bottom-0 left-0 ml-2 mb-2"
      />
    </div>
  );
};

export default RandomQuote;
