import React, { useEffect, useRef, useState } from "react";
import generatePolygons from "./Polygon";
import { COLORS } from "./Colors";

const Points = ({ blockSize, addBlock, block }) => {
  const canvasRef = useRef(null);
  const [showCuttingOrder, setShowCuttingOrder] = useState(false);
  const [showColors, setShowColors] = useState(false);
  const [colChoice, setColChoice] = useState(0);

  const handleClick = () => {
    const generatedPolygons = generatePolygons(blockSize);
    addBlock(generatedPolygons);
  };

  const toggleCuttingOrder = () => {
    setShowCuttingOrder(!showCuttingOrder);
  };

  const colChoiceHandler = (x) => {
    setColChoice(x);
  };

  const toggleShowColors = (x) => {
    setShowColors(!showColors);
  };

  useEffect(() => {
    if (!block.length) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw polygons
    let colorIndex = 0;
    block.forEach((block) => {
      const vertices = block.vertices;

      // Begin path
      ctx.beginPath();

      // Set the stroke color
      ctx.strokeStyle = "black";

      // Move to the first vertex
      ctx.moveTo(vertices[0].x, vertices[0].y);

      // Draw lines to the remaining vertices
      for (let i = 1; i < vertices.length; i++) {
        const vertex = vertices[i];
        ctx.lineTo(vertex.x, vertex.y);
      }

      // Close the path
      ctx.closePath();

      // Set the fill color with the desired alpha value
      if (showColors) {
        block.setColor(COLORS[colChoice][colorIndex]);
        colorIndex = (colorIndex + 1) % COLORS[colChoice].length;
      } else {
        block.setColor([255, 255, 255]);
      }

      ctx.fillStyle = `rgb(${block.color[0]}, ${block.color[1]}, ${block.color[2]})`;

      // Fill the polygon
      ctx.fill();

      // Set the stroke color with the desired alpha value
      ctx.strokeStyle = `rgb(0, 0, 0)`;

      // Stroke the polygon
      ctx.stroke();

      if (showCuttingOrder) {
        // Draw the centre point
        ctx.fillStyle = "black";
        ctx.fillRect(block.centrePoint.x - 2, block.centrePoint.y - 2, 4, 4);

        // Draw the cutting order
        ctx.fillStyle = "black";
        ctx.font = "12px Arial";
        ctx.fillText(
          block.cutting_order.toString(),
          block.centrePoint.x + 5,
          block.centrePoint.y + 5
        );
      }
    });
  }, [showCuttingOrder, block, showColors, colChoice, blockSize]);

  return (
    <div>
      <button onClick={handleClick} className="quicksand text-4xl">
        Generate Quilt Block
      </button>
      {block.length > 0 && (
        <>
          <button onClick={toggleCuttingOrder}> Toggle Cutting Order</button>
          <button onClick={toggleShowColors}>
            {showColors ? "Hide Colors" : "Show Colors"}
          </button>
          <button onClick={() => colChoiceHandler(0)}>Fire</button>
          <button onClick={() => colChoiceHandler(1)}>Blue</button>
          <button onClick={() => colChoiceHandler(2)}>Flesh</button>
          <button onClick={() => colChoiceHandler(3)}>Red</button>
          <button onClick={() => colChoiceHandler(4)}>Yellow</button>
          <canvas ref={canvasRef} width={blockSize} height={blockSize} />
        </>
      )}
    </div>
  );
};

export default Points;
