import React from "react";

const ExpenseCard = (props) => {
  return (
    <div key={props.id}>
      <p>Mileage: {props.mileage}</p>
      {props.litres ? <p>Total (Litres): {props.litres}</p> : null}
      {props.discs ? <p>Brake discs: {props.discs}</p> : null}
      {props.pads ? <p>Brake pads: {props.pads}</p> : null}
      {props.details ? <p>Details: {props.details}</p> : null}
      {props.type ? <p>Expense type: {props.type}</p> : null}
      {props.deets ? <p>Deets: {props.deets}</p> : null}
      {props.insurer ? <p>Insurer: {props.insurer}</p> : null}
      {props.mot ? <p>MOT: {props.mot}</p> : null}
      {props.notes ? <p>Notes: {props.notes}</p> : null}
      {props.date ? <p>Date: {props.date}</p> : null}
      {props.spend ? <p>Total spend: {props.spend}</p> : null}
      {props.tyres ? <p>Tyres replaced: {props.tyres}</p> : null}
      <br />
    </div>
  );
};

export default ExpenseCard;
