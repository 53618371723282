import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Points from "./Points";

const Quilt = () => {
  const [block, setBlock] = useState([]);
  const addBlockHandler = (newBlock) => {
    setBlock(newBlock);
    console.log(newBlock);
  };

  const [blockSize, setBlockSize] = useState(400);

  const addBlockSizeHandler = (newBlockSize) => {
    setBlockSize(newBlockSize);
  };

  return (
    <div className="m-4">
      <Helmet>
        <title>Quilt in a million</title>
      </Helmet>
      <Points
        blockSize={blockSize}
        addBlockSize={addBlockSizeHandler}
        block={block}
        addBlock={addBlockHandler}
      />
    </div>
  );
};

export default Quilt;
