import React from "react";
import { Helmet } from "react-helmet";
import VehicleExpenses from "./VehicleExpenses";

export default function Tracker() {
  return (
    <div className="bg-slate-200 flex">
      <Helmet>
        <title>Vehicle Tracker</title>
      </Helmet>

      <h1>Vehicle Tracker</h1>
      <VehicleExpenses />
    </div>
  );
}
