export const COLORS = [
  [
    [230, 230, 230],
    [179, 179, 179],
    [128, 128, 128],
    [77, 77, 77],
    [255, 125, 0],
  ],
  [
    [179, 205, 224],
    [100, 151, 177],
    [0, 91, 150],
    [3, 57, 108],
    [1, 31, 75],
  ],
  [
    [238, 193, 173],
    [219, 172, 152],
    [210, 153, 133],
    [201, 130, 118],
    [227, 93, 106],
  ],
  [
    [255, 0, 0],
    [191, 0, 0],
    [128, 0, 0],
    [64, 0, 0],
    [0, 0, 0],
  ],
  [
    [255, 255, 255],
    [204, 204, 204],
    [153, 153, 153],
    [255, 230, 0],
    [51, 51, 51],
  ],
];
